<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon"> 
      <v-row justify="center" >
        <v-col cols="9">
          <v-select
            v-model="selectedDatagroup"
            :items="objects"
            item-text="name"
            :item-value="getItemValue"
            dense
            outlined
            clearable
            background-color="white"
            hide-details
            placeholder="Selecione um nome"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            color="success"
            large
            :disabled="!selectedDatagroup"
            @click="loadResume()"
          >Buscar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <div v-for="item in items" :key="item.type">
      <v-row class="mb-3" v-if="items.length > 0">
        <v-col cols="12">
          <v-card rounded elevation="1">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col  xxl="4" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Setor</span>
                        <h2>{{ item.sectorName }}</h2>
                      </div>
                    </v-col>
                    <v-col  xxl="4" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Processo</span>
                        <h2 :style="item.name.length > 20 ? 'word-wrap: break-word; max-width: 70%;' : ''">{{ item.name }}</h2>
                      </div>
                    </v-col>
                    <!-- <v-col xxl="4" xl="3" lg="1" md="1" sm="1"></v-col> -->
                    <v-col xxl="4" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Responsável</span>
                        <h3>{{ item.positionName }}</h3>
                      </div>
                    </v-col>
                    <v-col xxl="4" xl="5" lg="3" md="3" sm="3" xs="12" class="d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Local</span>
                        <h3>{{ item.placeName }}</h3>
                      </div>
                      <div>
                        <v-chip label class="me-2 white--text" color="blue">{{ getTypeLabel(item.type) }}</v-chip>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="mb-3 mt-3"></v-divider>

              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col lg="6">
                      <span style="opacity: 0.5;">Código da Etapa</span>
                      <h3>{{ item.idEtapa }}</h3>
                    </v-col>
                    <v-col lg="6 d-flex flex-row justify-space-between">
                      <div>
                        <span style="opacity: 0.5;">Base Legal</span>
                        <h3 :style="getLegalLabel(item.legal).length > 30 ? 'word-wrap: break-word; max-width: 60%;' : ''">{{ getLegalLabel(item.legal) }}</h3>{{ getLegalLabel(item.legal).length }}
                      </div>
                      <div>
                        <span style="opacity: 0.5;">Consentimento</span>
                        <h3>{{ getConsentLabel(item.consent) }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import TheTitle from "@/components/TheTitle"

export default {
  name: "Dados",
  components: {
    TheTitle,
  },
  data: () => ({
    baseRoute: "reports/activityByHolder",
    title: "Atividades por Titular",
    pageIcon: "mdi-file-document-edit-outline",

    objects: [],
    items: [],
    position: [],

    selectedDatagroup: null,
    error: false,
    edit: false,

    type: [
      { text: "Coleta", value: "1" },
      { text: "Uso/Finalidade", value: "2" },
      { text: "Compartilhamento", value: "3" },
      { text: "Retenção/Armazenamento", value: "4" },
      { text: "Descarte", value: "5" },
    ],

    legal: [
      { text: "Consentimento do titular", value: "1" },
      { text: "Cumprimento de obrigação legal ou regulatória", value: "2" },
      { text: "Execução, pela administração pública, de políticas públicas", value: "3" },
      { text: "Realização de estudos por órgão de pesquisa", value: "4" },
      { text: "Execução de contrato", value: "5" },
      { text: "Diligências pré-contratuais", value: "6" },
      { text: "Exercício regular de direitos", value: "7" },
      { text: "Proteção da vida e da incolumidade física", value: "8" },
      { text: "Tutela da saúde", value: "9" },
      { text: "Legítimo Interesse", value: "10" },
      { text: "Proteção de crédito", value: "11" },
      { text: "Prevenção à fraude em sistemas eletrônicos", value: "12" },
      { text: "Segurança do titular em sistemas eletrônicos", value: "13" },
      { text: "Não atendida", value: "14" },
    ],

    consent: [
      { text: "De usuário", value: "1" },
      { text: "Presumido", value: "2" },
      { text: "Informado", value: "3" },
      { text: "Ativo", value: "4" },
    ],

  }),

  computed: {
    datagroupItems() {
      if (this.selectedDatagroup) {
        return [
          `${this.selectedDatagroup.id} ${this.selectedDatagroup.idCompany}`,
        ]
      }
      return []
    },
  },

  methods: {

    loadData() {
      const url = `${baseApiUrl}/datagroup`
      this.$store.dispatch("setLoadingInfo", true)
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
        })
        .catch(showError)
        .finally(() => {
          this.$store.dispatch("setLoadingInfo", null)
        })
    },

    async getIdPosition(value) {
      const positionName = await this.fetchPositionName(value)
      return positionName
    },

    getItemValue(item) {
      return `${item.id} ${item.idCompany}`
    },

    getTypeLabel(value) {
      const typeObj = this.type.find((item) => item.value === value)
      return typeObj ? typeObj.text : ""
    },

    getLegalLabel(value) {
      const legalObj = this.legal.find((item) => item.value === value)
      return legalObj ? legalObj.text : ""
    },

    getConsentLabel(value) {
      const consentObj = this.consent.find((item) => item.value === value)
      return consentObj ? consentObj.text : ""
    },

    async fetchSetorName(idSector) {
      const url = `${baseApiUrl}/sector/${idSector}`
      return axios.get(url).then((res) => res.data.name)
    },

    async fetchPositionName(idPosition) {
      const url = `${baseApiUrl}/position/${idPosition}`
      return axios.get(url).then((res) => res.data.name)
    },

    async fetchPlaceName(idPlace) {
      const url = `${baseApiUrl}/place/${idPlace}`
      return axios.get(url).then((res) => res.data.name)
    },

    async getIdPlaceLabel(value) {
      const placeName = await this.fetchPlaceName(value)
      return placeName
    },

    async loadResume() {
      if (!this.selectedDatagroup) {
        console.error("Nenhum valor selecionado no v-select.")
        return
      }
      const [id, idCompany] = this.selectedDatagroup.split(" ")
      const url = `${baseApiUrl}/activityByHolder?idDatagroup=${id}&idCompany=${idCompany}`
      this.$store.dispatch("setLoadingInfo", true)
      try {
        const res = await axios.get(url)
        const itemsWithNames = await Promise.all(
          res.data.map(async (item) => {
            const sectorName = await this.fetchSetorName(item.idSector)
            const positionName = await this.fetchPositionName(item.idPosition)
            const placeName = await this.fetchPlaceName(item.idPlace)
            return { ...item, sectorName, positionName, placeName }
          })
        )

        this.items = itemsWithNames

      } catch (error) {
        showError(error)
      } finally {
        this.$store.dispatch("setLoadingInfo", null)
      }
    },

  },

  mounted() {
    this.loadData()
  },
}
</script>
